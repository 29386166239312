import { TinyColor } from "@ctrl/tinycolor";
import React from "react";
import Item from "../api/structures/Item";
import { colorIntToHex } from "../Utils";
import "./VoteListItem.scss";
import API from "../api/API";
import Logger from "../Logger";
import UserComponent from "./UserComponent";
import { ItemEvent, ItemObserver } from "../api/providers/ItemProvider";
import { Subscription } from "rxjs";
import Option from "../api/structures/Option";

export interface VoteListItemProps {
    item: Item;
}

interface State {
    item: Item;
    userVote: number;
    votesExpanded: boolean;
}

const TAG = "VoteListItem";

export default class VoteListItem extends React.Component<VoteListItemProps, State> implements ItemObserver {

    constructor(props: VoteListItemProps) {
        super(props);
        this.castOwnVote = this.castOwnVote.bind(this);
        this.toggleVotersList = this.toggleVotersList.bind(this);
        this.state = {
            item: props.item,
            userVote: this.updateSelfVoteState(false, props.item),
            votesExpanded: false,
        };
    }

    private subscription?: Subscription;

    public componentDidMount() {
        this.subscription = API.providers.items.observe(this.props.item.id, this);
    }

    public componentWillUnmount() {
        this.subscription?.unsubscribe();
    }

    public next(event: ItemEvent): void {
        if (event.action === "itemUpdate") {
            this.setState({
                item: event.value,
            });
            this.updateSelfVoteState();
        }
    }

    private updateSelfVoteState(doSetState: boolean = true, item: Item = this.state.item): number {
        let userVote = -1;
        for (const [optionId, users] of item.votes.entries()) {
            if (users.has(API.getCurrentUserId())) {
                userVote = optionId;
                break;
            }
        }
        if (doSetState) {
            this.setState({
                userVote,
            });
        }
        return userVote;
    }

    private castOwnVote(option: Option): void {
        const item = this.state.item;
        if (!item.votingAllowed) {
            Logger.warn("VoteListItem", `Tried to cast vote for option ${option.id} while votes are closed`);
            return;
        }
        const previousVote = this.state.userVote;
        const removeVote = previousVote === option.id;
        this.setState({
            userVote: removeVote ? -1 : option.id,
        });
        const promise = removeVote ? item.deleteOwnVote() : item.castVote(option);
        promise.catch(e => {
            Logger.error(TAG, `Couldn't update vote for Option ${option.id} in Item ${item.id}`, e);
            // Reset visual state to previous vote
            this.setState({
                userVote: previousVote,
            });
        });
    }

    private toggleVotersList(): void {
        this.setState({
            votesExpanded: !this.state.votesExpanded,
        });
    }

    public render() {
        const state = this.state;
        const item = state.item;
        const votesOpen = item.votingAllowed;
        const voteCounts = item.votes.mapValues(v => v.size);
        const totalVoteCount = voteCounts.reduce((prev, curr) => prev + curr, 0);
        const usersWithoutVote = item.group.users.filter(u => !item.votes.some(voters => voters.has(u.id)));
        return (
            <div className={`vote ${votesOpen && state.userVote === -1 ? "new" : ""}`}>
                <div className="vote-container">
                    <div className="vote-header">
                        <span className="item-id">{item.id}. </span>
                        <span className="item-description">{item.name}</span>
                        <small className="item-total-votes">
                            {" "} - {totalVoteCount} votes - {" "}
                            <button className="vote-toggle-voters" onClick={this.toggleVotersList}>
                                {state.votesExpanded ? "Collapse" : "Expand"}
                                <span className="chevron-container">
                                    <span className={`chevron ${state.votesExpanded ? "expanded" : ""}`}>‹</span>
                                </span>
                            </button>
                        </small>
                    </div>
                    <div className="vote-actions-container">
                        <div className="vote-actions">
                            {item.group.options.map(option => {
                                const isCurrentlySelected = state.userVote === option.id;
                                const optionColor = new TinyColor(colorIntToHex(option.color));
                                const backgroundColor = isCurrentlySelected ?
                                    optionColor.setAlpha(0.5).toRgbString() :
                                    optionColor.setAlpha(0.2).toRgbString();
                                return (
                                    <button
                                        key={option.id}
                                        className={`vote-action ${isCurrentlySelected ? "active" : ""}`}
                                        style={{ backgroundColor }}
                                        onClick={() => this.castOwnVote(option)}
                                        disabled={!votesOpen}
                                        title={!votesOpen ? "Votes have been closed" : ""}>
                                        {option.name} ({voteCounts.get(option.id)})
                                    </button>
                                );
                            })}
                        </div>
                        {state.votesExpanded ? (
                            <div className="voters-container">
                                <div className="voters-list">
                                    {!totalVoteCount ?
                                        <span className="voters-no-votes">No votes so far.</span>
                                        :
                                        item.votes.map((v, i) => (
                                            <ul key={i} className="voters-list-col">
                                                {v.map(u => (
                                                    <li key={u.id} className="voters-list-item">• <UserComponent user={u} /></li>
                                                ))}
                                            </ul>
                                        ))
                                    }
                                </div>
                                <div className="voters-without-vote">
                                    {!usersWithoutVote.size ?
                                        <span className="voters-without-vote-title">Everybody voted!</span>
                                        :
                                        <>
                                            <span className="voters-without-vote-title">No vote yet from: </span>
                                            <ul className="voters-without-vote-user-list">
                                                {usersWithoutVote.map(u => (
                                                    <li key={u.id} className="voters-without-vote-user"><UserComponent user={u} /></li>
                                                ))}
                                            </ul>
                                        </>}
                                </div>
                            </div>
                        ) : <></>}
                    </div>
                </div>
            </div>
        );
    }

}
