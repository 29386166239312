import React from "react";
import { formatDateRelative, formatDate } from "../../Utils";

export interface LiveDateViewProps {
    date: number | Date;
    template?: string;
    dateFormat?: string;
}

interface State {
    updateInterval: number;
}

export default class LiveDateView extends React.Component<LiveDateViewProps, State> {

    constructor(props: LiveDateViewProps) {
        super(props);
        this.state = {
            updateInterval: this.calculateUpdateInterval(),
        };
    }

    private timeout?: NodeJS.Timeout;

    public render() {
        const output = (this.props.template || "{relative}")
            .replace("{relative}", formatDateRelative(this.props.date))
            .replace("{absolute}", formatDate(this.props.date, this.props.dateFormat));
        return (
            <span className="live-date-view">{output}</span>
        );
    }

    public componentDidMount() {
        this.createTimeout();
    }

    public componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    private createTimeout(): void {
        this.timeout = setTimeout(() => {
            this.setState({
                updateInterval: this.calculateUpdateInterval(),
            }, () => this.createTimeout());
        }, this.state.updateInterval);
    }

    private calculateUpdateInterval(): number {
        const date = new Date(this.props.date).getTime();
        const diff = Math.abs(Date.now() - date);
        if (diff < 1000 * 10) {
            return 1000 * 5;
        }
        if (diff < 1000 * 60) {
            return 1000 * 20;
        }
        if (diff < 1000 * 60 * 60) {
            return 1000 * 60;
        }
        return 1000 * 60 * 60;
    }

}
