import { ParsedUrlQueryInput, stringify } from "querystring";
import { version } from "../../package.json";
import Logger from "../Logger";
import User, { APIUser } from "./structures/User";
import WS from "./WS";
import GroupProvider from "./providers/GroupProvider";
import ItemProvider from "./providers/ItemProvider";
import UserProvider from "./providers/UserProvider";
import ToastProvider from "./providers/ToastProvider";
import { EventManager, EVENTS } from "./EventManager";

export interface APIResponse<T> {
    data: T;
    status: number;
    ok: boolean;
    raw: Response;
}

interface LoginResponse {
    token: string;
    user: APIUser;
}

interface LocalStorageAuth {
    token: string;
    user: APIUser;
    created: number;
}

const API_URL = "https://polls.ai-rub.de/api";
const LOCAL_STORAGE_AUTH_KEY = "auth";
const TOKEN_VALID_TIME = 1000 * 60 * 60 * 24;
const TAG = "API";

export default class API {

    public static providers = {
        groups: new GroupProvider(),
        items: new ItemProvider(),
        users: new UserProvider(),
        toasts: new ToastProvider(),
    };
    public static events: EventManager = new EventManager();
    public static ws: WS = new WS(API_URL);
    public static token?: string;
    private static currentUserId?: number;

    public static init(): void {
        const data = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
        if (data) {
            const cachedAuth: LocalStorageAuth = JSON.parse(data);
            if (cachedAuth.created + TOKEN_VALID_TIME < Date.now()) {
                API.logout();
                return;
            }
            API.token = cachedAuth.token;
            const currentUser = API.providers.users.addOrUpdate(cachedAuth.user);
            API.currentUserId = currentUser.id;
            API.events.dispatch(EVENTS.LOGIN, currentUser);
            API.events.dispatch(EVENTS.LOCAL_USER_UPDATE, currentUser);
            User.getSelf().catch(e => {
                Logger.warn(TAG, `Couldn't fetch user ${API.currentUserId} from session`, e);
            });
        }
    }

    public static isLoggedIn(): boolean {
        return this.currentUserId !== undefined;
    }

    public static getCurrentUser(): User | null {
        return API.isLoggedIn() ? API.providers.users.get(API.currentUserId!) || null : null;
    }

    public static hasAdminAccess(): boolean {
        return API.getCurrentUser()?.admin || false;
    }

    public static getCurrentUserId(): number {
        return API.isLoggedIn() ? API.currentUserId! : -1;
    }

    public static async login(username: string, password: string): Promise<User> {
        const res = await this.post<LoginResponse>("/login", {
            username,
            password,
        });
        const data = res.data;
        localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, JSON.stringify({
            token: data.token,
            user: data.user,
            created: Date.now(),
        } as LocalStorageAuth));
        API.token = data.token;
        const newUser = API.providers.users.addOrUpdate(data.user);
        API.currentUserId = data.user.id;
        API.events.dispatch(EVENTS.LOGIN, newUser);
        API.events.dispatch(EVENTS.LOCAL_USER_UPDATE, newUser);
        return newUser;
    }

    public static logout(): void {
        API.token = undefined;
        API.currentUserId = undefined;
        localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
        API.events.dispatch(EVENTS.LOCAL_USER_UPDATE, null);
        API.events.dispatch(EVENTS.LOGOUT);
    }

    public static async get<T = any>(endpoint: string, data?: object, throwOnBadResponse: boolean = true): Promise<APIResponse<T>> {
        return API.request("GET", endpoint, data, throwOnBadResponse);
    }

    public static async post<T = any>(endpoint: string, data: object, throwOnBadResponse: boolean = true): Promise<APIResponse<T>> {
        return API.request("POST", endpoint, data, throwOnBadResponse);
    }

    public static async patch<T = any>(endpoint: string, data: object, throwOnBadResponse: boolean = true): Promise<APIResponse<T>> {
        return API.request("PATCH", endpoint, data, throwOnBadResponse);
    }

    public static async put<T = any>(endpoint: string, data?: object, throwOnBadResponse: boolean = true): Promise<APIResponse<T>> {
        return API.request("PUT", endpoint, data, throwOnBadResponse);
    }

    public static async delete<T = any>(endpoint: string, data?: object, throwOnBadResponse: boolean = true): Promise<APIResponse<T>> {
        return API.request("DELETE", endpoint, data, throwOnBadResponse);
    }

    private static async request(method: string, endpoint: string,
        data?: object, throwOnBadResponse: boolean = true): Promise<APIResponse<any>> {
        method = method.toUpperCase();
        const get = method === "GET";
        const headers: any = {
            "User-Agent": `PollTool Frontend v${version}`,
        };
        if (API.token) {
            headers.Token = API.token;
        }
        if (!get && data) {
            headers["Content-Type"] = "application/json";
        }
        const url = `${API_URL}${endpoint}${(get && data) ? `?${stringify(data as ParsedUrlQueryInput)}` : ""}`;
        const response = await fetch(url, {
            method,
            headers,
            body: (get || !data) ? undefined : JSON.stringify(data),
        });
        let json: any = {};
        const text = await response.text();
        if (text) {
            try {
                json = JSON.parse(text);
            } catch {
                Logger.warn(TAG, `Couldn't parse response of ${method} ${url} as JSON`, { text });
                if (throwOnBadResponse) {
                    throw new Error(`Couldn't parse server response`);
                }
            }
        }
        const error = json.error || json.message;
        if ((!response.ok || error) && throwOnBadResponse) {
            const stringError = typeof error === "object" ? JSON.stringify(error) : error;
            Logger.warn(TAG, `API error: ${stringError}`);
            throw new Error(stringError);
        }
        return {
            data: json,
            status: response.status,
            ok: response.ok,
            raw: response,
        };

    }

}

API.init();
