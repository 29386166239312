import React from "react";
import Group from "../api/structures/Group";
import GroupListItem from "./GroupListItem";
import { Subscription } from "rxjs";
import API from "../api/API";
import { GroupEvent } from "../api/providers/GroupProvider";
import Collection from "@discordjs/collection";
import GenericMessage from "./util/GenericMessage";
import { Field, FieldArray, Form, Formik, FormikHelpers, FormikProps } from "formik";

export interface GroupListProps {
    groups?: Collection<number, Group>;
}

interface State {
    groups: Collection<number, Group>;
    createGroupExpanded: boolean;
}

interface CreateGroupFormValues {
    name: string;
    description: string;
    itemListTitle: string;
    options: { name: string; color: string; }[];
}

export default class GroupList extends React.Component<GroupListProps, State> {

    private initialFormValues: CreateGroupFormValues;

    constructor(props: GroupListProps) {
        super(props);
        this.submitCreateForm = this.submitCreateForm.bind(this);
        this.initialFormValues = {
            name: "",
            description: "",
            itemListTitle: "",
            options: [{
                name: "Dafür",
                color: "#00FF00",
            }, {
                name: "Enthaltung",
                color: "#FFFF00",
            }, {
                name: "Dagegen",
                color: "#FF0000",
            }],
        };
        this.state = {
            groups: props.groups || new Collection(),
            createGroupExpanded: false,
        };
    }

    private subscription?: Subscription;

    public componentDidMount() {
        this.subscription = API.providers.groups.observeAll(this, true);
    }

    public componentWillUnmount() {
        this.subscription?.unsubscribe();
    }

    public next(event: GroupEvent): void {
        if (event.action === "groupCreate") {
            this.setState(s => ({
                groups: s.groups.set(event.id, event.value),
            }));
        } else if (event.action === "groupDelete") {
            this.setState(s => ({
                groups: s.groups.filter(g => g.id !== event.id),
            }));
        } else if (event.action === "groupUpdate") {
            this.setState(s => ({
                groups: s.groups.mapValues(g => g.id === event.id ? event.value : g),
            }));
        }
    }

    public render() {
        const groups = this.state.groups;
        return (
            <>
                {API.hasAdminAccess() && <div className="panel">
                    <h2>Create New Group</h2>
                    {!this.state.createGroupExpanded ?
                        <div className="button-row">
                            <button
                                className="button"
                                onClick={() => this.setState({ createGroupExpanded: true })}>
                                Expand</button>
                        </div>
                        :
                        <Formik
                            initialValues={this.initialFormValues}
                            onSubmit={this.submitCreateForm}>
                            {(formik: FormikProps<CreateGroupFormValues>) => (
                                <Form className="table-row group group-edit-form">
                                    <div className="button-row">
                                        <button
                                            type="button"
                                            className="button"
                                            onClick={() => this.setState({ createGroupExpanded: false })}>
                                            Collapse</button>
                                    </div>
                                    <Field name="name"
                                        placeholder="Name*"
                                        className="form-input"
                                        maxLength={100}
                                        required={true} />
                                    <Field
                                        name="description"
                                        placeholder="Description"
                                        className="form-input"
                                        maxLength={100} />
                                    <Field
                                        name="itemListTitle"
                                        placeholder="Item List Title*"
                                        className="form-input"
                                        maxLength={100}
                                        required={true} />
                                    <FieldArray
                                        name="options"
                                        render={arrayHelpers => (<div>
                                            <div className="button-row">
                                                <button
                                                    type="button"
                                                    className="info"
                                                    onClick={() => arrayHelpers.push({ name: "", color: "#000000", })}>
                                                    Add Item</button>
                                            </div>
                                            {formik.values.options.map((o, i) => (
                                                <div key={i} style={{ display: "flex" }}>
                                                    <div className="center-container">
                                                        <div className="button-row">
                                                            <button
                                                                type="button"
                                                                className="danger"
                                                                onClick={() => arrayHelpers.remove(i)}>Remove</button>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Field
                                                            name={`options.${i}.name`}
                                                            placeholder="Option Name*"
                                                            className="form-input"
                                                            maxLength={100}
                                                            required={true} />
                                                        <Field
                                                            name={`options.${i}.color`}
                                                            placeholder="Option Color*"
                                                            className="form-input"
                                                            maxLength={100}
                                                            required={true} />
                                                    </div>
                                                </div>))}
                                        </div>)} />
                                    {formik.status || ""}
                                    <div className="button-row">
                                        <button type="submit" disabled={formik.isSubmitting} className="success">Create</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>}
                </div>}
                {!groups.size ?
                    <GenericMessage
                        title="No Groups"
                        description="There are no public groups right now." />
                    :
                    <div className="list-container-outer">
                        <h2 className="list-header">Groups ({groups.size})</h2>
                        <div className="list-container-inner custom-scrollbar">
                            <div className="table">
                                <div className="table-head">
                                    <div className="table-row">
                                        <div className="table-cell align-left">Name</div>
                                        <div className="table-cell align-right">Actions</div>
                                    </div>
                                </div>
                                <div className="table-body">
                                    {groups.map(g => <GroupListItem
                                        key={g.id}
                                        group={g} />,
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>}
            </>
        );
    }

    private async submitCreateForm(values: CreateGroupFormValues, formikActions: FormikHelpers<CreateGroupFormValues>): Promise<void> {
        try {
            await Group.create({
                name: values.name.trim(),
                description: values.description.trim(),
                item_list_title: values.itemListTitle.trim(),
                options: values.options.map(o => ({ name: o.name.trim(), color: parseInt(o.color.replace("#", "").trim(), 16) })),
            });
        } catch (err) {
            formikActions.setSubmitting(false);
            formikActions.setStatus(err.message);
        }
    }

}
