import Collection from "@discordjs/collection";
import API from "../API";
import Item, { APIItem } from "./Item";
import Option, { APIOption } from "./Option";
import Thing from "./Thing";
import User, { APIUser } from "./User";

export interface APIGroupPartial {
    id: number;
    name: string;
    description: string;
}

export interface APIGroup extends APIGroupPartial {
    item_creation_allowed: boolean;
    item_list_title: string;
    items: APIItem[];
    options: APIOption[];
}

export interface GroupPatchOptions {
    name?: string;
    description?: string;
    item_creation_allowed?: boolean;
    item_list_title?: string;
}

export interface GroupCreateOptions extends GroupPatchOptions {
    name: string;
    item_list_title: string;
    options: { name: string; color: number; }[];
}

export interface CreateItemOptions {
    name: string;
    voting_allowed?: boolean;
}

export default class Group extends Thing<APIGroup | APIGroupPartial> {

    public name!: string;
    public description!: string;
    public itemCreationAllowed: boolean = false;
    public itemListTitle: string = "";
    public items: Collection<number, Item> = new Collection();
    public options: Collection<number, Option> = new Collection();
    public users: Collection<number, User> = new Collection();

    public patch(thing: Partial<APIGroup>): this {
        if (typeof thing.name !== "undefined") {
            this.name = thing.name;
        }
        if (typeof thing.description !== "undefined") {
            this.description = thing.description;
        }
        if (typeof thing.item_creation_allowed !== "undefined") {
            this.itemCreationAllowed = thing.item_creation_allowed;
        }
        if (typeof thing.item_list_title !== "undefined") {
            this.itemListTitle = thing.item_list_title;
        }
        if (thing.items) {
            for (const i of thing.items) {
                this.items.set(i.id, API.providers.items.addOrUpdate(i));
            }
            for (const i of this.items.keys()) {
                if (!thing.items.find(ri => ri.id === i)) {
                    API.providers.items.delete(i);
                }
            }
        }
        if (thing.options) {
            for (const o of thing.options) {
                this.options.set(o.id, new Option(this, o));
            }
        }
        return this;
    }

    public static async create(data: GroupCreateOptions): Promise<Group> {
        const res = await API.post<APIGroup>("/groups", data);
        return API.providers.groups.addOrUpdate(res.data);
    }

    public static async getAll(): Promise<Group[]> {
        const res = await API.get<APIGroupPartial[]>(`/groups`);
        return res.data.map(g => API.providers.groups.addOrUpdate(g));
    }

    public static async get(id: number): Promise<Group> {
        const res = await API.get<APIGroup>(`/groups/${encodeURIComponent(id)}`);
        return API.providers.groups.addOrUpdate(res.data);
    }

    public static async join(id: number): Promise<void> {
        await API.put(`/groups/${encodeURIComponent(id)}/users/@me`);
    }

    public async edit(data: GroupPatchOptions): Promise<Group> {
        const res = await API.patch<APIGroup>(`/groups/${encodeURIComponent(this.id)}`, data);
        return API.providers.groups.addOrUpdate(res.data);
    }

    public async delete(): Promise<void> {
        await API.delete(`/groups/${encodeURIComponent(this.id)}`);
        API.providers.groups.delete(this.id);
    }

    public async createItem(data: CreateItemOptions): Promise<Item> {
        const res = await API.post<APIItem>(`/groups/${encodeURIComponent(this.id)}/items`, data);
        return API.providers.items.addOrUpdate(res.data);
    }

    public async fetchItems(): Promise<Collection<number, Item>> {
        const res = await API.get<APIItem[]>(`/groups/${encodeURIComponent(this.id)}/items`);
        this.patch({
            items: res.data,
        });
        return this.items;
    }

    public async fetchUsers(): Promise<Collection<number, User>> {
        const res = await API.get<APIUser[]>(`/groups/${encodeURIComponent(this.id)}/users`);
        for (const u of res.data) {
            this.users.set(u.id, API.providers.users.addOrUpdate(u));
        }
        for (const u of this.users.keys()) {
            if (!res.data.find(usr => usr.id === u)) {
                this.users.delete(u);
            }
        }
        API.providers.groups.dispatchUpdate(this);
        return this.users;
    }

    public async leave(): Promise<void> {
        await API.delete(`/groups/${encodeURIComponent(this.id)}/users/@me`);
    }

    public _addOrRemoveUser(user: User, add: boolean): void {
        if (add ? this.users.has(user.id) : !this.users.has(user.id)) {
            return;
        }
        if (add) {
            this.users.set(user.id, user);
        } else {
            this.users.delete(user.id);
        }
        API.providers.groups.dispatchUpdate(this);
    }

}
