import Provider, { EventBase } from "./Provider";
import { NextObserver } from "rxjs";

export type ToastEventActions = "toastCreate" | "toastDelete";
export type ToastEvent = EventBase<Toast, ToastEventActions>;
export type ToastObserver = NextObserver<ToastEvent>;

export type ToastLevel = "warn" | "error";
export interface Toast {
    id: number;
    level: ToastLevel;
    message: string;
}

export default class ToastProvider extends Provider<Toast, Toast, ToastEventActions> {

    protected initialEventAction: ToastEventActions = "toastCreate";

    protected async fetchInitialData(): Promise<ToastEvent[]> {
        return [];
    }

    public addToast(level: ToastLevel, message: string): void {
        this.addOrUpdate({
            id: Math.random(),
            level,
            message,
        });
    }

    public addOrUpdate(data: Toast): Toast {
        this.dispatch({
            id: data.id,
            action: "toastCreate",
            value: data,
        });
        return data;
    }

    public delete(id: number): void {
        const existing = this.cache.get(id);
        if (existing) {
            this.cache.delete(id);
            this.dispatch({
                id: existing.id,
                action: "toastDelete",
                value: existing,
            });
        }
    }

}
