import Provider, { EventBase } from "./Provider";
import Group, { APIGroup, APIGroupPartial } from "../structures/Group";
import { NextObserver } from "rxjs";
import API from "../API";

export type GroupEventActions = "groupCreate" | "groupUpdate" | "groupDelete";
export type GroupEvent = EventBase<Group, GroupEventActions>;
export type GroupObserver = NextObserver<GroupEvent>;

export default class GroupProvider extends Provider<APIGroup | APIGroupPartial, Group, GroupEventActions> {

    protected initialEventAction: GroupEventActions = "groupCreate";

    protected async fetchInitialData(): Promise<GroupEvent[]> {
        const groups = await Group.getAll();
        return groups.map(g => ({
            id: g.id,
            action: "groupCreate",
            value: g,
        }));
    }

    public dispatchUpdate(group: Group) {
        this.dispatch({
            id: group.id,
            action: "groupUpdate",
            value: group,
        });
    }

    public addOrUpdate(data: APIGroup | APIGroupPartial): Group {
        const existing = this.cache.get(data.id);
        if (existing) {
            const beforeUpdate = existing.update(data);
            if (beforeUpdate.equals(existing)) {
                return existing;
            }
            this.dispatch({
                id: existing.id,
                action: "groupUpdate",
                value: existing,
            });
            return existing;
        }
        const g = new Group(data);
        this.cache.set(data.id, g);
        g.patch(data);
        this.dispatch({
            id: g.id,
            action: "groupCreate",
            value: g,
        });
        return g;
    }

    public delete(id: number): void {
        const existing = this.cache.get(id);
        if (existing) {
            for (const itemId of existing.items.keys()) {
                API.providers.items.delete(itemId);
            }
            this.cache.delete(id);
            this.dispatch({
                id: existing.id,
                action: "groupDelete",
                value: existing,
            });
        }
    }

}
