import API from "../API";
import Thing from "./Thing";

export interface APIUser {
    id: number;
    name: string;
    flags?: number;
}

export interface UserPatchOptions {
    name?: string;
}

export enum UserFlags {
    ADMIN = 1,
}

export default class User extends Thing<APIUser> {

    public name!: string;
    public flags: number = 0;

    public patch(thing: Partial<APIUser>): this {
        if (thing.name) {
            this.name = thing.name;
        }
        if (typeof thing.flags !== "undefined") {
            this.flags = thing.flags;
        }
        return this;
    }

    public get admin(): boolean {
        return (this.flags & UserFlags.ADMIN) === UserFlags.ADMIN;
    }

    public static async getSelf(): Promise<User> {
        const res = await API.get<APIUser>(`/users/@me`);
        return API.providers.users.addOrUpdate(res.data);
    }

    public static async get(id: number): Promise<User> {
        const res = await API.get<APIUser>(`/users/${encodeURIComponent(id)}`);
        return API.providers.users.addOrUpdate(res.data);
    }

    public async edit(data: UserPatchOptions): Promise<void> {
        const res = await API.patch<APIUser>(`/users/${encodeURIComponent(this.id)}`, data);
        this.patch(res.data);
    }

    public async delete(): Promise<void> {
        await API.delete(`/users/${encodeURIComponent(this.id)}`);
    }

}
