import React, { ErrorInfo } from "react";
import { stringify } from "../../Utils";
import GenericMessage from "./GenericMessage";
import Logger from "../../Logger";

interface State {
    error?: string;
}

type Props = {};

export default class ErrorBoundary extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {};
      }

    static getDerivedStateFromError(error: any) {
        return {
            error: stringify(error),
        };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        Logger.error("ErrorBoundary", "Caught Error from component", { error, errorInfo });
    }

    render() {
        if (this.state.error) {
            return <GenericMessage
                title="Unknown Error"
                description={<>There was an uncaught error in the app:<br />{this.state.error}</>} />
        }
        return this.props.children;
    }
}
