import Collection from "@discordjs/collection";
import { PartialObserver, Subject, Subscription } from "rxjs";
import User from "./structures/User";

export enum EVENTS {
    LOGIN = "login",
    LOGOUT = "logout",
    LOCAL_USER_UPDATE = "localUserUpdate",
}

export interface EventArgs {
    login: User;
    logout: undefined;
    localUserUpdate: User | null;
}

export class EventManager {

    private subjects: Collection<string, Subject<any>> = new Collection();

    public observe<K extends keyof EventArgs>(event: K, observer: PartialObserver<EventArgs[K]>): Subscription {
        if (!this.subjects.has(event)) {
            this.subjects.set(event, new Subject());
        }
        return this.subjects.get(event)!.subscribe(observer);
    }

    public dispatch<K extends keyof EventArgs>(event: K, data?: EventArgs[K]): void {
        const subject = this.subjects.get(event);
        if (!subject) {
            return;
        }
        setTimeout(() => {
            subject.next(data);
        }, 0);
    }

}