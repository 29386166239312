import React, { ReactChild } from "react";
import Logger from "../../Logger";
import { CancelablePromise, makeCancelable, stringify } from "../../Utils";
import GenericMessage from "./GenericMessage";

export interface LoadingContainerProps<FetchResult, BaseProps> {
    fetch: () => Promise<FetchResult>;
    component: React.ComponentType<FetchResult & BaseProps>;
    baseProps?: BaseProps;
    loadingTitle?: string;
    loadingMessage?: string;
    errorTitle?: string;
    errorMessage?: string | ReactChild | ((errorDescription?: string) => ReactChild);
}

interface State<FetchResult> {
    data?: FetchResult;
    loadError: any;
    loading: boolean;
}

const TAG = "LoadingContainer";

export default class LoadingContainer<FetchResult, BaseProps = {}> extends
    React.Component<LoadingContainerProps<FetchResult, BaseProps>, State<FetchResult>> {

    private loadingPromise?: CancelablePromise<FetchResult>;

    constructor(props: LoadingContainerProps<FetchResult, BaseProps>) {
        super(props);
        this.state = {
            data: undefined,
            loadError: null,
            loading: true,
        };
    }

    public componentDidMount(): void {
        if (!this.state.data) {
            this.setState({
                loading: true,
            });
            this.loadingPromise = makeCancelable(this.props.fetch());
            this.loadingPromise.promise.then(data => {
                this.setState({
                    data,
                    loading: false,
                });
            }).catch(err => {
                this.setState({
                    loadError: err,
                    loading: false,
                });
                Logger.error(TAG, "Failed to fetch data", err);
            });
        }
    }

    public componentWillUnmount(): void {
        if (this.loadingPromise) {
            this.loadingPromise.cancel();
        }
    }

    public render() {
        if (this.state.loading) {
            return <GenericMessage
                title={this.props.loadingTitle || "Loading"}
                description={this.props.loadingMessage || "Fetching some data..."}
                showLoading={true} />;
        }
        if (this.state.loadError) {
            let { errorMessage } = this.props;
            if (!errorMessage) {
                errorMessage = <>Couldn't load data from the server:<br />{stringify(this.state.loadError)}</>;
            }
            if (typeof errorMessage === "function") {
                errorMessage = errorMessage(stringify(this.state.loadError));
            }
            return <GenericMessage
                title={this.props.errorTitle || "Error"}
                description={errorMessage} />;
        }
        const Component = this.props.component;
        const props = { ...this.props.baseProps!, ...this.state.data! };
        return (
            <Component {...props} />
        );
    }

}
