import Provider, { EventBase } from "./Provider";
import Item, { APIItem } from "../structures/Item";
import { NextObserver } from "rxjs";
import API from "../API";

export type ItemEventActions = "itemCreate" | "itemUpdate" | "itemDelete";
export type ItemEvent = EventBase<Item, ItemEventActions>;
export type ItemObserver = NextObserver<ItemEvent>;

export default class ItemProvider extends Provider<APIItem, Item, ItemEventActions> {

    protected initialEventAction: ItemEventActions = "itemCreate";

    protected async fetchInitialData(): Promise<ItemEvent[]> {
        return [];
    }

    public dispatchUpdate(item: Item) {
        this.dispatch({
            id: item.id,
            action: "itemUpdate",
            value: item,
        });
    }

    public addOrUpdate(data: APIItem): Item {
        const existing = this.cache.get(data.id);
        if (existing) {
            const beforeUpdate = existing.update(data);
            if (beforeUpdate.equals(existing)) {
                return existing;
            }
            this.dispatch({
                id: existing.id,
                action: "itemUpdate",
                value: existing,
            });
            return existing;
        }
        const group = API.providers.groups.get(data.group_id);
        if (!group) {
            throw new Error(`Cannot add Item because of unknown Group ${(data as APIItem).group_id}`);
        }
        const i = new Item(group, data);
        this.cache.set(data.id, i);
        i.patch(data);
        this.dispatch({
            id: data.id,
            action: "itemCreate",
            value: i,
        });
        return i;
    }

    public delete(id: number): void {
        const existing = this.cache.get(id);
        if (existing) {
            existing.group.items.delete(id);
            this.cache.delete(id);
            this.dispatch({
                id: existing.id,
                action: "itemDelete",
                value: existing,
            });
        }
    }

}
