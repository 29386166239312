import Group from "./Group";
import Thing from "./Thing";

export interface APIOption {
    id: number;
    name: string;
    color: number;
}

export default class Option extends Thing<APIOption> {

    public group: Group;
    public name!: string;
    public color!: number;

    constructor(group: Group, thing: APIOption) {
        super(thing);
        this.group = group;
        this.patch(thing);
        group.options.set(this.id, this);
    }

    public patch(thing: Partial<APIOption>): this {
        if (thing.name) {
            this.name = thing.name;
        }
        if (typeof thing.color !== "undefined") {
            this.color = thing.color;
        }
        return this;
    }

}
