import React from "react";
import { Link } from "react-router-dom";
import GenericMessage from "../components/util/GenericMessage";

const NotFound: React.FC = () => {
    return <GenericMessage
        title="Page Not Found"
        description={<Link to="/" className="not-found-link">Go Back Home</Link>} />;
};

export default NotFound;
