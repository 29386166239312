import React from "react";
import { Link } from "react-router-dom";
import "./GroupListItem.scss";
import Group from "../api/structures/Group";
import { Field, FormikProps, ErrorMessage, Formik, Form, FormikHelpers } from "formik";
import API from "../api/API";
import Logger from "../Logger";

export interface GroupListItemProps {
    group: Group;
}

interface State {
    isEditing: boolean;
}

interface EditGroupFormValues {
    name: string;
    description: string;
}

const TAG = "GroupListItem";

export default class GroupListItem extends React.Component<GroupListItemProps, State> {

    private initialFormValues: EditGroupFormValues;

    constructor(props: GroupListItemProps) {
        super(props);
        this.startEditing = this.startEditing.bind(this);
        this.stopEditing = this.stopEditing.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.submitEditForm = this.submitEditForm.bind(this);
        this.initialFormValues = {
            name: props.group.name,
            description: props.group.description,
        };
        this.state = {
            isEditing: false,
        };
    }

    public render() {
        const group = this.props.group;
        if (this.state.isEditing) {
            return (
                <Formik
                    initialValues={this.initialFormValues}
                    onSubmit={this.submitEditForm}>
                    {(formik: FormikProps<EditGroupFormValues>) => (
                        <Form className="table-row group group-edit-form">
                            <div className="table-cell group-name-container">
                                <Field name="name"
                                    placeholder="Name"
                                    className="form-input"
                                    maxLength={100}
                                    validate={(v: string) => !v.trim().length ? "Name is required" : undefined} />
                                <ErrorMessage name="name" />
                                <Field
                                    name="description"
                                    placeholder="Description"
                                    className="form-input"
                                    maxLength={100} />
                                {formik.status || ""}
                            </div>
                            <div className="table-cell group-actions align-right">
                                <div className="button-row">
                                    <button type="button" onClick={this.confirmDelete} className="danger">Delete</button>
                                    <button type="button" onClick={this.stopEditing} className="warn">Cancel</button>
                                    <button type="submit" disabled={formik.isSubmitting} className="success">Save</button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            );
        }
        return (
            <div className="table-row group">
                <div className="table-cell group-name-container">
                    <strong className="group-name">{group.name}</strong>
                    <br />
                    <span className="group-description">{group.description}</span>
                </div>
                <div className="table-cell group-actions align-right">
                    <div className="button-row">
                        <Link to={`/groups/${group.id}`} className="link-button">View</Link>
                        {API.hasAdminAccess() ? (
                            <>
                                <button type="button" onClick={this.startEditing}>Edit</button>
                            </>
                        ) : ""}
                    </div>
                </div>
            </div>
        );
    }

    private startEditing(): void {
        this.setState({
            isEditing: true,
        });
    }

    private stopEditing(): void {
        this.setState({
            isEditing: false,
        });
    }

    private confirmDelete(): void {
        const group = this.props.group;
        // eslint-disable-next-line no-restricted-globals
        if (!confirm(`Delete Group '${group.name}' (${group.id})?`)) {
            return;
        }
        group.delete().catch(e => {
            Logger.error(TAG, `Couldn't delete Group '${group.id}'`, e);
        });
    }

    private async submitEditForm(values: EditGroupFormValues, formikActions: FormikHelpers<EditGroupFormValues>): Promise<void> {
        try {
            const group = this.props.group;
            if (group.name !== values.name || group.description !== values.description) {
                await group.edit({
                    name: values.name.trim(),
                    description: values.description.trim(),
                });
            }
            this.setState({
                isEditing: false,
            });
        } catch (err) {
            formikActions.setSubmitting(false);
            formikActions.setStatus(err.message);
        }
    }

}
