import React, { ReactChild } from "react";
import "./GenericMessage.scss";

export interface GenericMessageProps {
    title: ReactChild;
    description: ReactChild;
    showLoading?: boolean;
}

const GenericMessage: React.FC<GenericMessageProps> = (props: GenericMessageProps) => {
    return (
        <div className="center-container generic-message-container">
            <img src="/images/ai-logo.png" className="main-logo" alt="[AI]" />
            {props.showLoading ? (<div className="generic-message-loading-container" >
                <svg className="loading-spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle className="loading-spinner-path" fill="none" strokeWidth="6"
                        strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                </svg>
            </div>) : ""}
            <h2 className="generic-message-title">{props.title}</h2>
            <div className="generic-message-description">{props.description}</div>
        </div>
    );
};

export default GenericMessage;
