import React from "react";
import { Redirect, useParams } from "react-router-dom";
import API from "../api/API";
import Group from "../api/structures/Group";
import GroupComponent from "./GroupComponent";
import GenericMessage from "./util/GenericMessage";
import LoadingContainer from "./util/LoadingContainer";

const GroupRoute: React.FC = () => {
    const { groupId } = useParams<{ groupId: string }>();
    if (!groupId || !/^\d+$/.test(groupId)) {
        return (
            <GenericMessage
                title="Error"
                description="Invalid Group ID. What are you doing?" />
        );
    }
    if (!API.isLoggedIn()) {
        return (
            <Redirect to="/login" />
        );
    }
    return (
        <LoadingContainer
            component={GroupComponent}
            fetch={async () => {
                const gid = +groupId;
                await Group.join(gid);
                const group = await Group.get(gid);
                await group.fetchUsers();
                return { group };
            }}
            loadingMessage="Fetching group data..." />
    );
};

export default GroupRoute;
