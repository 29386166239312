import Collection from "@discordjs/collection";
import Logger from "../../Logger";
import API from "../API";
import Group from "./Group";
import Option, { APIOption } from "./Option";
import Thing from "./Thing";
import User, { APIUser } from "./User";

export interface APIItem {
    id: number;
    group_id: number;
    name: string;
    deleted: boolean;
    created_at: number;
    voting_allowed: boolean;
    author: APIUser;
}

export interface ItemPatchOptions {
    name?: string;
    deleted?: boolean;
    voting_allowed?: boolean;
}

const TAG = "Item";

export default class Item extends Thing<APIItem> {

    public group: Group;
    public name!: string;
    public deleted!: boolean;
    public createdAt!: number;
    public votingAllowed!: boolean;
    public author!: User;
    public votes: Collection<number, Collection<number, User>> = new Collection(); // Map<optionId, Map<userId, User>>

    constructor(group: Group, thing: APIItem) {
        super(thing);
        this.group = group;
        group.items.set(this.id, this);
    }

    public patch(thing: Partial<APIItem>): this {
        if (thing.name) {
            this.name = thing.name;
        }
        if (typeof thing.deleted !== "undefined") {
            this.deleted = thing.deleted;
        }
        if (thing.created_at) {
            this.createdAt = thing.created_at * 1000;
        }
        if (thing.author) {
            this.author = API.providers.users.addOrUpdate(thing.author);
        }
        if (typeof thing.voting_allowed !== "undefined") {
            this.votingAllowed = thing.voting_allowed;
        }
        if (thing.group_id && thing.group_id !== this.group.id) {
            throw new Error(`User Group ID '${thing.group_id}' does not match group object ID '${this.group.id}'`);
        }
        return this;
    }

    public async edit(data: ItemPatchOptions): Promise<void> {
        const res = await API.patch<APIItem>(`/items/${encodeURIComponent(this.id)}`, data);
        this.patch(res.data);
        API.providers.items.dispatchUpdate(this);
    }

    public async delete(): Promise<void> {
        await API.delete(`/items/${encodeURIComponent(this.id)}`);
        API.providers.items.delete(this.id);
    }

    public async fetchVotes(): Promise<Collection<number, Collection<number, User>>> {
        const res = await API.get<(APIOption & { voters: APIUser[] })[]>(`/items/${encodeURIComponent(this.id)}/votes`);
        for (const option of res.data) {
            this.votes.set(option.id, new Collection(option.voters.map(v => [v.id, API.providers.users.addOrUpdate(v)])));
            API.providers.items.dispatchUpdate(this);
        }
        return this.votes;
    }

    public async castVote(option: Option): Promise<void> {
        await API.put(`/items/${encodeURIComponent(this.id)}/votes/@me/${encodeURIComponent(option.id)}`);
        const uid = API.getCurrentUserId();
        if (uid !== -1) {
            this._updateVoteForUser(uid, option.id);
        }
    }

    public async deleteOwnVote(): Promise<void> {
        await API.delete(`/items/${encodeURIComponent(this.id)}/votes/@me`);
        const uid = API.getCurrentUserId();
        if (uid !== -1) {
            this._updateVoteForUser(uid, -1);
        }
    }

    public async deleteAllVotes(): Promise<void> {
        await API.delete(`/items/${encodeURIComponent(this.id)}/votes`);
    }

    public _updateVoteForUser(uid: number, oid: number): void {
        const user = API.providers.users.get(uid);
        if (!user) {
            return Logger.error(TAG, `Unknown user '${uid}'`);
        }
        if (oid !== -1 && !this.votes.has(oid)) {
            throw new Error(`Unknown option ${oid} on item ${this.id}`);
        }
        const currentVote = this.votes.findKey(o => o.has(uid)) ?? -1;
        if (currentVote === oid) {
            return;
        }
        if (currentVote !== -1) {
            this.votes.get(currentVote)!.delete(uid);
        }
        if (oid !== -1) {
            this.votes.get(oid)!.set(uid, user);
        }
        API.providers.items.dispatchUpdate(this);
    }

}
