import React from "react";
import VoteListItem from "./VoteListItem";
import "./VoteList.scss";
import Item from "../api/structures/Item";
import Collection from "@discordjs/collection";
import { ItemEvent, ItemObserver } from "../api/providers/ItemProvider";
import { Subscription } from "rxjs";
import API from "../api/API";
import Group from "../api/structures/Group";

export interface VoteListProps {
    group: Group;
    items: Collection<number, Item>;
}

interface State {
    items: Collection<number, Item>;
}

export default class VoteList extends React.Component<VoteListProps, State> implements ItemObserver {

    constructor(props: VoteListProps) {
        super(props);
        this.state = {
            items: props.items,
        };
    }

    private subscription?: Subscription;

    public componentDidMount() {
        this.subscription = API.providers.items.observeAll(this, true);
    }

    public componentWillUnmount() {
        this.subscription?.unsubscribe();
    }

    public next(event: ItemEvent): void {
        if (event.value.group.id !== this.props.group.id) {
            return;
        }
        const item = event.value;
        if (event.action === "itemCreate" && item.votingAllowed) {
            this.addItem(item);
        } else if (event.action === "itemDelete" && this.state.items.has(item.id)) {
            this.removeItem(item);
        } else if (event.action === "itemUpdate") {
            if (item.votingAllowed && !this.state.items.has(item.id)) {
                this.addItem(item);
            } else if (!item.votingAllowed && this.state.items.has(item.id)) {
                this.removeItem(item);
            }
        }
    }

    private addItem(item: Item): void {
        this.setState(s => ({
            items: s.items.set(item.id, item),
        }));
    }

    private removeItem(item: Item): void {
        this.setState(s => ({
            items: s.items.filter(i => i.id !== item.id)
        }));
    }

    public render() {
        const state = this.state;
        return (
            <div className="vote-list">
                <h2 className="vote-list-header list-header">Open Votes ({state.items.size})</h2>
                <div className="votes-container custom-scrollbar">
                    <div className="votes">
                        {state.items.map(i => <VoteListItem key={i.id} item={i} />)}
                    </div>
                </div>
            </div>
        );
    }

}
