import Provider, { EventBase } from "./Provider";
import User, { APIUser } from "../structures/User";
import { NextObserver } from "rxjs";
import API from "../API";
import { EVENTS } from "../EventManager";

export type UserEventActions = "userCreate" | "userUpdate" | "userDelete";
export type UserEvent = EventBase<User, UserEventActions>;
export type UserObserver = NextObserver<UserEvent>;

export default class UserProvider extends Provider<APIUser, User, UserEventActions> {

    protected initialEventAction: UserEventActions = "userCreate";

    protected async fetchInitialData(): Promise<UserEvent[]> {
        return [];
    }

    public addOrUpdate(data: APIUser): User {
        const existing = this.cache.get(data.id);
        if (existing) {
            const beforeUpdate = existing.update(data);
            if (beforeUpdate.equals(existing)) {
                return existing;
            }
            existing.patch(data);
            this.dispatch({
                id: existing.id,
                action: "userUpdate",
                value: existing,
            });
            if (existing.id === API.getCurrentUserId()) {
                API.events.dispatch(EVENTS.LOCAL_USER_UPDATE, existing);
            }
            return existing;
        }
        const u = new User(data);
        this.cache.set(data.id, u);
        u.patch(data);
        this.dispatch({
            id: data.id,
            action: "userCreate",
            value: u,
        });
        return u;
    }

    public delete(id: number): void {
        const existing = this.cache.get(id);
        if (existing) {
            this.cache.delete(id);
            this.dispatch({
                id: existing.id,
                action: "userDelete",
                value: existing,
            });
        }
    }

}
