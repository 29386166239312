import { TinyColor } from "@ctrl/tinycolor";
import Tippy from "@tippy.js/react";
import React from "react";
import "tippy.js/dist/tippy.css";
import Item from "../api/structures/Item";
import { colorIntToHex, formatDate } from "../Utils";
import "./ItemListItem.scss";
import UserComponent from "./UserComponent";
import { Subscription } from "rxjs";
import { ItemEvent, ItemObserver } from "../api/providers/ItemProvider";
import API from "../api/API";
import LiveDateView from "./util/LiveDateView";
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import Logger from "../Logger";

export interface ItemListItemProps {
    item: Item;
}

interface State {
    item: Item;
    isEditing: boolean;
}

interface EditItemFormValues {
    name: string;
}

const TAG = "ItemListItem";

export default class ItemListItem extends React.Component<ItemListItemProps, State> implements ItemObserver {

    private initialFormValues: EditItemFormValues;

    constructor(props: ItemListItemProps) {
        super(props);
        this.toggleVotes = this.toggleVotes.bind(this);
        this.startEditing = this.startEditing.bind(this);
        this.stopEditing = this.stopEditing.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.submitEditForm = this.submitEditForm.bind(this);
        this.initialFormValues = {
            name: props.item.name,
        };
        this.state = {
            item: props.item,
            isEditing: false,
        };
    }

    private subscription?: Subscription;

    public componentDidMount() {
        this.subscription = API.providers.items.observe(this.props.item.id, this);
    }

    public componentWillUnmount() {
        this.subscription?.unsubscribe();
    }

    public next(event: ItemEvent): void {
        if (event.action === "itemUpdate") {
            this.setState({
                item: event.value,
            });
            this.initialFormValues = {
                name: event.value.name,
            };
        }
    }

    public render() {
        const state = this.state;
        const item = state.item;
        if (state.isEditing) {
            return (
                <Formik
                    initialValues={this.initialFormValues}
                    onSubmit={this.submitEditForm}>
                    {(formik: FormikProps<EditItemFormValues>) => (
                        <Form className="table-row">
                            <div className="table-cell">
                                <Field
                                    name="name"
                                    placeholder="Name"
                                    className="form-input"
                                    maxLength={100}
                                    validate={(v: string) => !v.trim().length ? "Name is required" : undefined} />
                                <ErrorMessage name="name" />
                                {formik.status || ""}
                            </div>
                            <div className="table-cell"></div>
                            <div className="table-cell align-right">
                                <div className="button-row">
                                    <button type="button" onClick={this.confirmDelete} className="danger">Delete</button>
                                    <button type="button" onClick={this.stopEditing} className="warn">Cancel</button>
                                    <button type="submit" disabled={formik.isSubmitting} className="success">Save</button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            );
        }
        const voteCounts = item.votes.mapValues(v => v.size);
        const totalVoteCount = voteCounts.reduce((prev, curr) => prev + curr, 0);
        return (
            <div className="table-row item" style={item.votingAllowed ? { backgroundColor: "rgba(0, 255, 0, 0.125)" } : {}}>
                <div className="table-cell item-description-container">
                    <div className="item-author-container">
                        <span className="item-author"><UserComponent user={item.author} /></span> suggested
                        <Tippy content={formatDate(item.createdAt)}>
                            <span>
                                <LiveDateView date={item.createdAt} template=" {relative}" />
                            </span>
                        </Tippy>:
                    </div>
                    <div className="item-description">
                        <span className="item-id">{item.id}. </span>
                        {item.name}
                    </div>
                </div>
                <div className="table-cell item-votes">
                    <Tippy content={`Total Votes: ${totalVoteCount}`}>
                        <div>
                            {item.group.options.map(option => {
                                return (
                                    <span key={option.id} style={{
                                        color: new TinyColor(colorIntToHex(option.color)).toRgbString(),
                                    }}>{voteCounts.get(option.id)}</span>
                                );
                            })}
                        </div>
                    </Tippy>
                </div>
                {API.hasAdminAccess() && (
                    <div className="table-cell align-right">
                        <div className="button-row">
                            <button onClick={this.startEditing}>Edit</button>
                            <button onClick={this.toggleVotes}>{item.votingAllowed ? "Close" : "Open"} Votes</button>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    private toggleVotes(): void {
        const item = this.state.item;
        const oldState = item.votingAllowed;
        item.edit({ voting_allowed: !oldState }).catch(e => {
            Logger.error(TAG, `Couldn't open votes for Item ${item.id}`, e);
        }).then(() => item.fetchVotes()).catch(e => {
            Logger.error(TAG, `Couldn't fetch new votes after toggling votes for Item ${item.id}`, e);
        });
    }

    private startEditing(): void {
        this.setState({
            isEditing: true,
        });
    }

    private stopEditing(): void {
        this.setState({
            isEditing: false,
        });
    }

    private confirmDelete(): void {
        const item = this.props.item;
        // eslint-disable-next-line no-restricted-globals
        if (!confirm(`Delete Item '${item.name}' (${item.id})?`)) {
            return;
        }
        item.delete().catch(e => {
            Logger.error(TAG, `Couldn't delete Item '${item.id}'`, e);
        });
    }

    private async submitEditForm(values: EditItemFormValues, formikActions: FormikHelpers<EditItemFormValues>): Promise<void> {
        try {
            const item = this.state.item;
            if (item.name !== values.name) {
                await item.edit({
                    name: values.name.trim(),
                });
            }
            this.setState({
                isEditing: false,
            });
        } catch (err) {
            formikActions.setSubmitting(false);
            formikActions.setStatus(err.message);
        }
    }

}
