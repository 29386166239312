import React from "react";
import { Redirect } from "react-router-dom";
import API from "../api/API";

const Logout: React.FC = () => {
    API.logout();
    return (
        <Redirect to="/login" />
    );
};

export default Logout;
