import Collection from "@discordjs/collection";
import { Subject, NextObserver, Subscription } from "rxjs";
import Logger from "../../Logger";

export interface EventBase<Type, Action> {
    id: number;
    action: Action;
    isInitial?: boolean;
    value: Type;
}

export default abstract class Provider<APIType, Type extends { id: number }, Action> {

    private subject: Subject<EventBase<Type, Action>> = new Subject();
    protected cache: Collection<number, Type> = new Collection();
    private hasFetchedInitial: boolean = false;
    protected abstract initialEventAction: Action;

    protected abstract async fetchInitialData(): Promise<EventBase<Type, Action>[]>;

    private maybeFetchInitialData(fetchInitial: boolean): void {
        if (fetchInitial && !this.hasFetchedInitial) {
            this.hasFetchedInitial = true;
            Logger.debug(this.constructor.name, `Fetching initial data`);
            this.fetchInitialData().then(events => {
                for (const val of events) {
                    this.dispatch(val);
                }
            }).catch(e => {
                Logger.error(this.constructor.name, "Couldn't load initial Provider data", e);
            });
        }
    }

    public observeAll(observer: NextObserver<EventBase<Type, Action>>, loadInitial: boolean = false): Subscription {
        if (loadInitial) {
            this.maybeFetchInitialData(loadInitial);
            for (const i of this.cache.values()) {
                observer.next({
                    id: i.id,
                    action: this.initialEventAction,
                    isInitial: true,
                    value: i,
                });
            }
        }
        return this.subject.subscribe(observer);
    }

    public observe(id: number, observer: NextObserver<EventBase<Type, Action>>, loadInitial: boolean = false): Subscription {
        if (loadInitial) {
            this.maybeFetchInitialData(loadInitial);
            const d = this.cache.get(id);
            if (d) {
                observer.next({
                    id: d.id,
                    action: this.initialEventAction,
                    isInitial: true,
                    value: d,
                });
            }
        }
        return this.subject.subscribe({
            ...observer,
            next: (value: EventBase<Type, Action>) => {
                if (value.id === id) {
                    observer.next(value);
                }
            },
        });
    }

    protected dispatch(data: EventBase<Type, Action>): void {
        this.subject.next(data);
    }

    public abstract addOrUpdate(data: APIType): void;
    public abstract delete(id: number): void;

    public get(id: number): Type | undefined {
        return this.cache.get(id);
    }

}
