import React from "react";
import "tippy.js/dist/tippy.css";
import Tippy from "@tippy.js/react";
import User from "../api/structures/User";
import API from "../api/API";
import { Subscription } from "rxjs";
import { UserEvent } from "../api/providers/UserProvider";

export interface UserComponentProps {
    user: User;
}

interface State {
    user: User;
}

export default class UserComponent extends React.Component<UserComponentProps, State> {

    constructor(props: UserComponentProps) {
        super(props);
        this.state = {
            user: props.user,
        };
    }

    private subscription?: Subscription;

    public componentDidMount() {
        this.subscription = API.providers.users.observe(this.props.user.id, this);
    }

    public componentWillUnmount() {
        this.subscription?.unsubscribe();
    }

    public next(event: UserEvent): void {
        this.setState(_ => ({
            user: event.value,
        }));
    }

    render() {
        const state = this.state;
        if (API.hasAdminAccess()) {
            return (
                <Tippy content={`ID: ${state.user.id}${state.user.admin ? " [A]" : ""}`}>
                    <span>
                        {state.user.name}
                    </span>
                </Tippy>
            );
        }
        return (
            <>
                {state.user.name}
            </>
        );
    }
};
