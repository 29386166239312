import Logger from "../Logger";
import API from "./API";
import socketIOClient from "socket.io-client";
import { EVENTS } from "./EventManager";
import { APIItem } from "./structures/Item";
import { APIUser } from "./structures/User";

const TAG = "WebSocket";

export default class WS {

    private socket?: SocketIOClient.Socket;
    private currentGroupSubscription?: number;
    private sendQueue: { action: string; data: any; }[] = [];

    public constructor(readonly baseUrl: string) {
        API.events.observe(EVENTS.LOGIN, {
            next: () => {
                this.openSocket(baseUrl.replace(/^https/, "wss")).catch(e => {
                    Logger.error(TAG, "Error opening WS", e);
                });
            },
        });
    }

    private async openSocket(url: string): Promise<void> {
        Logger.info(TAG, `Connecting to ${url}`);
        const slashIndex = url.indexOf("/", 6);
        const socket = this.socket = socketIOClient(url.substring(0, slashIndex), {
            transports: ["websocket"],
            path: `${url.substring(slashIndex)}/socket.io/`,
            reconnectionAttempts: 5,
        });
        socket.io.on("reconnect_attempt", (attempt: number) => {
            Logger.info(TAG, `Attempting reconnect ${attempt}`);
        });
        socket.io.on("error", (err: object) => {
            Logger.error(TAG, "WS error", err);
        });
        socket.io.on("connect_error", (err: object) => {
            Logger.error(TAG, "WS connect error", err);
        });
        socket.io.on("connect_timeout", (timeout: number) => {
            Logger.error(TAG, `WS connect timeout after ${timeout}`);
        });
        socket.io.on("reconnecting", () => {
            Logger.info(TAG, "WS reconnecting");
        });
        socket.on("disconnect", (reason: string) => {
            Logger.info(TAG, `WS disconnected with reason '${reason}'`);
        });
        socket.on("connect", async () => {
            Logger.info(TAG, `WS connected successfully`);
            this.sendMessage("auth", {
                token: API.token,
            });
            const currendSubscription = this.currentGroupSubscription;
            this.currentGroupSubscription = undefined;
            if (currendSubscription) {
                this.addGroupSubscription(currendSubscription);
            }
            for (const i of this.sendQueue) {
                socket.emit(i.action, i.data);
            }
            this.sendQueue = [];
        });
        await this.setupListeners(this.socket);
        return new Promise((resolve, reject) => {
            socket.once("error", reject)
            socket.once("connect", () => {
                socket.off("error", reject);
                resolve();
            })
        });
    }

    public addGroupSubscription(id: number): void {
        if (this.currentGroupSubscription === id) {
            Logger.info(TAG, `Already subscribed to Group ${id}`);
            return;
        }
        Logger.debug(TAG, `Adding subscription for Group ${id}`);
        this.currentGroupSubscription = id;
        this.sendMessage("join", {
            group_id: id,
        });
    }

    public removeGroupSubscription(id: number): void {
        if (this.currentGroupSubscription === undefined) {
            Logger.info(TAG, `Not currently subscribed to any group`);
            return;
        }
        Logger.debug(TAG, `Removing subscription for Group ${id}`);
        this.currentGroupSubscription = undefined;
        this.sendMessage("leave", {
            group_id: id,
        });
    }

    private sendMessage(action: string, data: any): void {
        if (!this.socket) {
            return Logger.warn(TAG, `Can't send data without WS`, { action, data });
        }
        if (!this.socket.connected) {
            Logger.info(TAG, `WS not open, pusing into send queue`, { action, data });
            this.sendQueue.push({
                action,
                data,
            });
            return;
        }
        this.socket.emit(action, data);
    }

    private async setupListeners(socket: SocketIOClient.Socket): Promise<void> {
        socket.on("CreateItem", (data: APIItem) => {
            API.providers.items.addOrUpdate(data);
        });
        socket.on("PatchItem", (data: APIItem) => {
            API.providers.items.addOrUpdate(data);
        });
        socket.on("DeleteItem", (data: APIItem) => {
            API.providers.items.delete(data.id);
        });

        socket.on("Join", (data: WSUserJoinLeaveEvent) => {
            this.addOrRemoveGroupUser(data.group_id, data.user, true);
        });
        socket.on("Leave", (data: WSUserJoinLeaveEvent) => {
            this.addOrRemoveGroupUser(data.group_id, data.user, false);
        });

        socket.on("CreateVote", (data: WSVoteCreateUpdateEvent) => {
            this.updateItemVote(data.item_id, data.option_id, data.user);
        });
        socket.on("PatchVote", (data: WSVoteCreateUpdateEvent) => {
            this.updateItemVote(data.item_id, data.option_id, data.user);
        });
        socket.on("DeleteVote", (data: WSVoteDeleteEvent) => {
            this.updateItemVote(data.item_id, -1, data.user_id);
        });
        socket.on("DeleteAllVote", (data: WSAllVoteDeleteEvent) => {
            this.updateItemVote(data.item_id, -1, null);
        });
    }

    private addOrRemoveGroupUser(gid: number, apiUser: APIUser, add: boolean): void {
        const group = API.providers.groups.get(gid);
        if (!group) {
            return Logger.warn(TAG, `Got join/leave event for unknown group '${gid}'`);
        }
        const user = API.providers.users.addOrUpdate(apiUser);
        group._addOrRemoveUser(user, add);
    }

    private updateItemVote(itemId: number, optionId: number, user: APIUser | number | null): void {
        const item = API.providers.items.get(itemId);
        if (!item) {
            return Logger.warn(TAG, `Got vote event for unknown item '${itemId}'`);
        }
        if (user !== null && typeof user === "object") {
            API.providers.users.addOrUpdate(user);
        }
        const uids = user !== null ? [typeof user === "number" ? user : user.id] : item.votes.map(v => v.map(u => u.id)).flat();
        for (const uid of uids) {
            item._updateVoteForUser(uid, optionId);
        }
    }

}

interface WSVoteCreateUpdateEvent {
    item_id: number;
    option_id: number;
    user: APIUser;
}
interface WSVoteDeleteEvent {
    item_id: number;
    user_id: number;
}
interface WSAllVoteDeleteEvent {
    item_id: number;
}
interface WSUserJoinLeaveEvent {
    group_id: number;
    user: APIUser;
}
