import Collection from "@discordjs/collection";
import React from "react";
import { Subscription } from "rxjs";
import API from "../../api/API";
import { Toast, ToastEvent, ToastObserver } from "../../api/providers/ToastProvider";
import "./ToastComponent.scss";

interface State {
    toasts: Collection<number, Toast>;
}

export default class ToastComponent extends React.Component<{}, State> implements ToastObserver {

    state = {
        toasts: new Collection<number, Toast>(),
    };

    private subscription?: Subscription;

    public componentDidMount() {
        this.subscription = API.providers.toasts.observeAll(this);
    }

    public componentWillUnmount() {
        this.subscription?.unsubscribe();
    }

    public next(event: ToastEvent): void {
        if (event.action === "toastCreate") {
            this.setState(prev => ({
                toasts: prev.toasts.set(event.id, event.value),
            }));
        } else if (event.action === "toastDelete") {
            this.dismissToast(event.id);
        }
    }

    dismissToast = (id: number) => {
        this.setState(prev => ({
            toasts: prev.toasts.filter(t => t.id !== id),
        }));
    }

    public render() {
        const state = this.state;
        return (
            <div className="toast-container">
                {state.toasts.map(toast => (
                    <div key={toast.id} className={`toast ${toast.level}`}>
                        <div className="toast-content">{toast.message}</div>
                        <div className="toast-actions">
                            <button onClick={() => this.dismissToast(toast.id)} className="toast-close-button"></button>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
};
