import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import GroupList from "../components/GroupList";
import GroupRoute from "../components/GroupRoute";
import Header from "../components/util/Header";
import ErrorBoundary from "../components/util/ErrorBoundary";
import "./App.scss";
import Login from "./Login";
import Logout from "./Logout";
import Main from "./Main";
import NotFound from "./NotFound";
import ToastComponent from "../components/util/ToastComponent";

const App: React.FC = () => {
    return (
        <Router>
            <div className="app">
                <Header />
                <div className="content">
                    <ErrorBoundary>
                        <Switch>
                            <Route exact path="/">
                                <Main />
                            </Route>
                            <Route path="/groups/:groupId">
                                <GroupRoute />
                            </Route>
                            <Route path="/groups">
                                <GroupList />
                            </Route>
                            <Route path="/login">
                                <Login />
                            </Route>
                            <Route path="/logout">
                                <Logout />
                            </Route>
                            <Route path="/">
                                <NotFound />
                            </Route>
                        </Switch>
                        <ToastComponent />
                    </ErrorBoundary>
                </div>
            </div>
        </Router>
    );
};

export default App;
