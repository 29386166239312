import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import API from "../../api/API";
import { EVENTS } from "../../api/EventManager";
import "./Header.scss";

const Header: React.FC = () => {

    const [user, setUser] = useState(API.getCurrentUser());
    useEffect(() => {
        const subscription = API.events.observe(EVENTS.LOCAL_USER_UPDATE, {
            next: u => {
                setUser(u);
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    });
    return (
        <header className="header">
            <Link to="/" className="header-main-link">
                <img src="/images/ai-logo-white.png" className="header-logo" alt="[AI]" />
                <h1 className="header-title">Poll Tool</h1>
            </Link>
            {user ? <Link to="/logout">{user.name} - Log Out</Link> : <Link to="/login">Log In</Link>}
        </header>
    );
};

export default Header;
