import { formatDistanceToNow, format } from "date-fns";

export const formatDateRelative = (date: Date | number): string => formatDistanceToNow(date, { addSuffix: true, includeSeconds: true });
export const formatDate = (date: Date | number, formatString?: string): string => format(date, formatString || "yyyy-MM-dd HH:mm:ss zzzz");

export const stringify = (thing: any): string => {
    if (typeof thing === "string") {
        return thing;
    }
    if (thing instanceof Error) {
        return thing.message;
    }
    return JSON.stringify(thing);
};

export const colorIntToHex = (color: number) => color.toString(16).padStart(6, "0");

export interface CancelablePromise<T> {
    promise: Promise<T>;
    cancel: () => void;
}

export const makeCancelable = <T>(promise: Promise<T>): CancelablePromise<T> => {
    let isCanceled = false;
    const wrapper = new Promise<T>((resolve, reject) => {
        promise.then(
            val => isCanceled ? void 0 : resolve(val),
            error => isCanceled ? void 0 : reject(error),
        );
    });
    return {
        promise: wrapper,
        cancel() {
            isCanceled = true;
        },
    };
};
