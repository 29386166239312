import API from "./api/API";

export default class Logger {

    public static debug(tag: string, message: string, data?: any): void {
        // tslint:disable-next-line:no-console
        console.debug(...Logger.createLogArgs("debug", tag, message, data));
    }

    public static info(tag: string, message: string, data?: any): void {
        // tslint:disable-next-line:no-console
        console.info(...Logger.createLogArgs("info", tag, message, data));
    }

    public static warn(tag: string, message: string, data?: any): void {
        // tslint:disable-next-line:no-console
        console.warn(...Logger.createLogArgs("warn", tag, message, data));
        API.providers.toasts.addToast("warn", `${tag}: ${message}`);
    }

    public static error(tag: string, message: string, data?: any): void {
        // tslint:disable-next-line:no-console
        console.error(...Logger.createLogArgs("error", tag, message, data));
        API.providers.toasts.addToast("error", `${tag}: ${message}`);
    }

    private static createLogArgs(level: string, tag: string, message: string, data?: any): any[] {
        return [`${level}/${tag}: ${message}`, data].filter(i => i);
    }

}
