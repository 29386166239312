import { isEqual } from "lodash";

export interface APIThing {
    id: number;
}

export default abstract class Thing<ApiType extends APIThing> {

    public id: number;

    constructor(thing: ApiType) {
        this.id = thing.id;
    }

    public abstract patch(data: ApiType): this;

    public update(data: ApiType): Thing<ApiType> {
        const clone = this.clone();
        this.patch(data);
        return clone;
    }

    private clone(): Thing<ApiType> {
      return Object.assign(Object.create(this), this);
    }

    public equals(obj: Thing<any>): boolean {
        if (!(obj instanceof this.constructor) || this.id !== obj.id) {
            return false;
        }
        return isEqual(this, obj);
    }

}
