import React from "react";
import Item from "../api/structures/Item";
import ItemListItem from "./ItemListItem";
import API from "../api/API";
import { Subscription } from "rxjs";
import { ItemEvent, ItemObserver } from "../api/providers/ItemProvider";
import Collection from "@discordjs/collection";
import Logger from "../Logger";
import Group from "../api/structures/Group";

export interface ItemListProps {
    group: Group;
    items: Collection<number, Item>;
    title: string;
}

interface State {
    items: Collection<number, Item>;
}

const TAG = "ItemList";
let nextVoteFetchPromise: Promise<any> | undefined;

export default class ItemList extends React.Component<ItemListProps, State> implements ItemObserver {

    constructor(props: ItemListProps) {
        super(props);
        this.state = {
            items: props.items,
        };
    }

    private subscription?: Subscription;

    public componentDidMount() {
        this.subscription = API.providers.items.observeAll(this, true);
    }

    public componentWillUnmount() {
        this.subscription?.unsubscribe();
    }

    public next(event: ItemEvent): void {
        if (event.value.group.id !== this.props.group.id) {
            return;
        }
        if (event.action === "itemCreate") {
            this.setState(s => ({
                items: s.items.set(event.id, event.value),
            }));
            this.queueItemVotesFetch(event.value);
        } else if (event.action === "itemDelete") {
            this.setState(s => ({
                items: s.items.filter(i => i.id !== event.id)
            }));
        }
    }

    private queueItemVotesFetch(item: Item) {
        if (nextVoteFetchPromise) {
            nextVoteFetchPromise = nextVoteFetchPromise.then(() => item.fetchVotes());
        } else {
            nextVoteFetchPromise = item.fetchVotes();
        }
        nextVoteFetchPromise = nextVoteFetchPromise!.catch(e => {
            Logger.error(TAG, `Couldn't fetch votes for Item ${item.id}`, e);
        });
    }

    public render() {
        const items = this.state.items;
        return (
            <div className="list-container-outer">
                <h2 className="list-header">{this.props.title} ({items.size})</h2>
                <div className="list-container-inner custom-scrollbar">
                    <div className="table">
                        <div className="table-head">
                            <div className="table-row">
                                <div className="table-cell align-left">Description</div>
                                <div className="table-cell align-center">Votes</div>
                                {API.hasAdminAccess() && <div className="table-cell align-right">Actions</div>}
                            </div>
                        </div>
                        <div className="table-body">
                            {items.map(p => <ItemListItem
                                key={p.id}
                                item={p} />,
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
