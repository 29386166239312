import React from "react";
import Group from "../api/structures/Group";
import ItemList from "./ItemList";
import VoteList from "./VoteList";
import API from "../api/API";
import { Subscription } from "rxjs";
import { GroupEvent, GroupObserver } from "../api/providers/GroupProvider";
import "./GroupComponent.scss";
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import Logger from "../Logger";

export interface GroupComponentProps {
    group: Group;
}

interface State {
    group: Group;
}

interface CreateItemFormValues {
    name: string;
}

const TAG = "GroupComponent";

export default class GroupComponent extends React.Component<GroupComponentProps, State> implements GroupObserver {

    private initialFormValues: CreateItemFormValues;

    constructor(props: GroupComponentProps) {
        super(props);
        this.submitCreationForm = this.submitCreationForm.bind(this);
        this.toggleItemCreation = this.toggleItemCreation.bind(this);
        this.initialFormValues = {
            name: "",
        };
        this.state = {
            group: props.group,
        };
    }

    private subscription?: Subscription;

    public componentDidMount() {
        const groupId = this.props.group.id;
        this.subscription = API.providers.groups.observe(groupId, this);
        API.ws.addGroupSubscription(groupId);
    }

    public componentWillUnmount() {
        const groupId = this.props.group.id;
        this.subscription?.unsubscribe();
        API.ws.removeGroupSubscription(groupId);
    }

    public next(event: GroupEvent): void {
        if (event.action === "groupUpdate") {
            this.setState({
                group: event.value,
            });
        }
    }

    public render() {
        const group = this.state.group;
        const isAdmin = API.hasAdminAccess();
        return (
            <div className="group">
                <div className="panel">
                    <h2>{group.name}</h2>
                    <span>{group.description}</span>
                </div>
                {(group.itemCreationAllowed || isAdmin) && <Formik
                    initialValues={this.initialFormValues}
                    onSubmit={this.submitCreationForm}>
                    {(formik: FormikProps<CreateItemFormValues>) => (
                        <Form className="panel group-create-item-container">
                            {group.itemCreationAllowed ? (
                                <>
                                    <Field
                                        name="name"
                                        placeholder="Submit new Item"
                                        className="form-input"
                                        maxLength={100}
                                        validate={(v: string) => v.trim().length > 100 ? "Too long" : !v.trim().length} />
                                    <ErrorMessage name="name" />
                                    {formik.status || ""}
                                </>
                            ) : (
                                    isAdmin && <div className="item-creation-disabled-notice">
                                        Item submissions are currently disabled.
                                    </div>
                                )}
                            <div className="button-row">
                                {group.itemCreationAllowed &&
                                    <button type="submit" disabled={formik.isSubmitting}>Submit</button>}
                                {isAdmin &&
                                    <button type="button"
                                        onClick={this.toggleItemCreation}
                                        className={group.itemCreationAllowed ? "danger" : "success"}>
                                        {group.itemCreationAllowed ? "Disable Submissions" : "Allow Submissions"}
                                    </button>}
                            </div>
                        </Form>
                    )}
                </Formik>}

                <VoteList
                    group={group}
                    items={group.items.filter(i => i.votingAllowed)} />
                <ItemList
                    group={group}
                    items={group.items}
                    title={group.itemListTitle} />
            </div>
        );
    }

    private toggleItemCreation(): void {
        const group = this.state.group;
        const oldState = group.itemCreationAllowed;
        group.edit({ item_creation_allowed: !oldState }).catch(e => {
            Logger.error(TAG, `Couldn't set item_creation_allowed for for Group ${group.id}`, e);
        });
    }

    private async submitCreationForm(values: CreateItemFormValues, formikActions: FormikHelpers<CreateItemFormValues>): Promise<void> {
        try {
            await this.state.group.createItem({
                name: values.name.trim(),
            });
            formikActions.resetForm();
        } catch (err) {
            formikActions.setSubmitting(false);
            formikActions.setStatus(err.message);
        }
    }

};
