import React from "react";
import { Redirect } from "react-router-dom";
import "./Main.scss";

const Main: React.FC = () => {
    return (
        <Redirect to="/groups" />
    );
};

export default Main;
