import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { Redirect } from "react-router";
import API from "../api/API";
import "./Login.scss";

interface LoginProps {
    initialValues?: Partial<LoginFormValues>;
}

interface LoginFormValues {
    username: string;
    password: string;
}

export default class Login extends React.Component<LoginProps> {

    private initialFormValues: LoginFormValues;

    constructor(props: LoginProps) {
        super(props);
        this.validateUsername = this.validateUsername.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.initialFormValues = {
            ...props.initialValues,
            ...{
                username: "",
                password: "",
            },
        };
    }

    public render() {
        if (API.isLoggedIn()) {
            return (<Redirect to="/" />);
        }
        return (
            <div className="center-container">
                <img src="/images/ai-logo.png" className="main-logo" alt="[AI]" />
                <div className="login-header-text">
                    Please choose a username and password below.<br /><br />
                    This account is local to the PollTool and <em>not</em> connected to your [AI] account.
                    Feel free to choose any nice-looking name instead of your [AI] username.<br /><br />
                    If for some reason you were logged out, you can use the same username-password combination to log back in.
                </div>
                <Formik
                    initialValues={this.initialFormValues}
                    onSubmit={this.submitForm}>
                    {({ status, isSubmitting }) => (
                        <Form className="form login-form">
                            <Field
                                name="username"
                                placeholder="Username"
                                autoComplete="username"
                                className="form-input"
                                maxLength={25}
                                validate={this.validateUsername} />
                            <ErrorMessage name="username" />
                            <Field
                                name="password"
                                placeholder="Password"
                                type="password"
                                autoComplete="password"
                                className="form-input"
                                validate={this.validatePassword} />
                            <ErrorMessage name="password" />
                            {status || ""}
                            <button type="submit" disabled={isSubmitting} className="form-button">Submit</button>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }

    private validateUsername(value: string): string | undefined {
        value = value.trim();
        if (!value) {
            return "Username required";
        }
        if (value.length > 50) {
            return "u wot";
        }
        if (value.length > 25) {
            return "Username too long";
        }
        return undefined;
    }

    private validatePassword(value: string): string | undefined {
        value = value.trim();
        if (!value) {
            return "Password required";
        }
        if (value.length < 5) {
            return "Password too short";
        }
        if (value.length > 100) {
            return "u wot";
        }
        if (value.length > 50) {
            return "Password too long for my taste";
        }
        return undefined;
    }

    private async submitForm(values: LoginFormValues, formikActions: FormikHelpers<LoginFormValues>): Promise<void> {
        try {
            await API.login(values.username.trim(), values.password.trim());
            this.forceUpdate();
        } catch (err) {
            formikActions.setSubmitting(false);
            formikActions.setStatus(err.message);
        }
    }

}
